import React, { FC, FormEvent, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Layout, { NavBarStatus } from "../components/Layout";
import styles from "../style";
import { UserInfo, decryptToken } from "../service/auth";
import { CONFIG, CURRENCY_KSH, CURRENCY_NGN, RUN_MODE } from "../constants";

const Deposit: React.FC = () => {
    const navigate = useNavigate();
    let [searchParams] = useSearchParams();
    const [userId, setUserId] = useState('');
    const [emailaddress, setEmailAddress] = useState('');
    const [username, setUsername] = useState('');
    const [amount, setAmount] = useState(0);
    const [message, setMessage] = useState('');
    const [gateway, setGateway] = useState('paystack');
    const [currency, setCurrency] = useState('ngn');
    const [isCurrencyDisabled, setIsCurrencyDisabled] = useState(false);

    useEffect(() => {


        const token = localStorage.getItem("token");
        //if(token == null || token == "")
        //    navigate("/login", {state:{prevPage:"/deposit"}});
        if (token) {
            const userInfo: UserInfo = decryptToken(token);
            setUserId(userInfo.id);
            setUsername(userInfo.name);
            setEmailAddress(userInfo.email);
        }

        const param_email = searchParams.get("email");
        console.log("email", param_email);
        if (param_email)
            setEmailAddress(param_email);
        const param_username = searchParams.get("username");
        if (param_username)
            setUsername(param_username);

        setGateway('paystack');
        setIsCurrencyDisabled(true);
    }, []);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.log("Deposit handleSubmit");

        if (emailaddress == "")
            return;

        const params = {
            email: emailaddress,
            amount,
            currency: currency == 'ngn' ? CURRENCY_NGN : CURRENCY_KSH
        };
        const token = localStorage.getItem("token");
        let url = '';

        switch (gateway) {
            case 'paystack':
                url = `${CONFIG[RUN_MODE].BACKEND_URL}/deposit`;
                break;
            case 'marasoftpay':
                url = `${CONFIG[RUN_MODE].BACKEND_URL}/marasoftpay/deposit`;
                break;
            case 'credo':
                url = `${CONFIG[RUN_MODE].BACKEND_URL}/credo/deposit`;
                break;
        }
        const response: Response = await fetch(url,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Whot ${token}`
                },
                body: JSON.stringify(params)
            });

        const responseData = await response.json();
        console.log("Deposit response", responseData);

        if (responseData.ret == 2) {
            localStorage.setItem("token", '');
            navigate("/login", { state: { prevPage: "/deposit" } });
            return;
        } else if (responseData.ret == 3) {
            console.log("response eror:", responseData.msg);
            setMessage(responseData.msg);
            return;
        }

        window.location.href = responseData.url;
    };

    const handleGateway = (event: any) => {
        setGateway(event.target.value);
        setCurrency('ngn');

        const disabled: boolean = event.target.value === "paystack" || event.target.value === "credo";
        setIsCurrencyDisabled(disabled);
    };

    const handleUseNGN = (event: any) => {
        setCurrency(event.target.value);
    };

    return (
        <Layout>
            <div className={`${styles.flexCenter} bg-main-backcolor`}>
                <div className={`w-full max-w-[470px]`}>
                    <h1 className="font-lexend font-medium text-[52px] text-black text-center mb-[12px] mt-[12px]">Deposit</h1>
                    <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                        <div className="mb-4">
                            <label className="font-lexend font-normal block text-black text-sm font-bold mb-2" htmlFor="username">
                                Email address
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="Email address"
                                type="text"
                                placeholder="adanbarnett114@gmail.com"
                                value={emailaddress}
                                onChange={(e) => setEmailAddress(e.target.value)}
                                disabled />
                        </div>
                        <div className="mb-4">
                            <label className="font-lexend font-normal block text-black text-sm font-bold mb-2" htmlFor="name">
                                Name
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="Name"
                                type="text"
                                placeholder="Player"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                disabled />
                        </div>

                        <div className="mb-4">
                            <label className="font-lexend font-normal block text-black text-sm font-bold mb-2" htmlFor="amount">
                                Amount
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="Name"
                                type="text"
                                placeholder="Amount"
                                value={amount}
                                onChange={(e) => { var t = parseInt(e.target.value, 10); t = isNaN(t) ? 0 : t; setAmount(t) }}
                            />
                        </div>

                        <div className="mb-4">
                            <label className="font-lexend font-normal block text-black text-sm font-bold mb-2" htmlFor="gateway">
                                Payment Gateway
                            </label>

                            <select
                                id="gateway"
                                name="gateway"
                                onChange={handleGateway}
                                className={`max-w-lg pl-2 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border 'border-gray-300' rounded-md`}
                                value={gateway}
                            >
                                <option key="paystack" value="paystack" selected={true}>Paystack</option>
                                <option key="credo" value="credo">Credo</option>
                                <option key="marasoftpay" value="marasoftpay">Whot pay</option>
                            </select>
                        </div>

                        <div className="mb-4">
                            <label className="font-lexend font-normal block text-black text-sm font-bold mb-2" htmlFor="currency">
                                Currency
                            </label>
                            <select
                                id="currency"
                                name="currency"
                                onChange={handleUseNGN}
                                className={`max-w-lg pl-2 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border 'border-gray-300' rounded-md`}
                                value={currency}
                                disabled={isCurrencyDisabled}
                            >
                                <option key="ngn" value="ngn" selected={true}>NGN</option>
                                <option key="ksh" value="ksh">KSH</option>
                            </select>
                        </div>

                        <span className="font-lexend font-normal block text-black text-sm font-bold mb-5">
                            The Bank transfer payment method for virtual banks may fail, we recommend you preferably use conventional banks for this method.
                        </span>
                        {
                            message == '' ?
                                (<></>) :
                                (<span className="font-lexend font-normal block text-blood text-sm mb-5">
                                    {message}
                                </span>
                                )
                        }
                        <div className="flex items-center justify-between space-x-[30px]">
                            <button className="w-1/2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                                Back
                            </button>
                            <button className="w-1/2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    );
};

export default Deposit;